import { Feature } from 'geojson';

export function geoJsonToRdf(geojson: Feature) {
  if (!geojson || !geojson.geometry || !Array.isArray(geojson.geometry.coordinates)) {
    throw new Error('Invalid GeoJSON object');
  }
  const geometryId = `<#geometry>`;
  const featureId = `<#>`;

  const polygonCoordinates = geojson.geometry.coordinates[0]
    .map((coord) => coord.join(' '))
    .join(',');
  const polygonWKT = `"POLYGON((${polygonCoordinates}))"^^<http://www.opengis.net/ont/geosparql#wktliteral>`;

  const rdfTriples = `${geometryId} <http://www.opengis.net/ont/geosparql#asWKT> ${polygonWKT}.\n${featureId} a <http://rmagro.org/rmagro#CropField>, <http://www.opengis.net/ont/geosparql#Feature>;\n  <http://www.w3.org/2000/01/rdf-schema#label> "${geojson.properties.name}";\n  <http://www.opengis.net/ont/geosparql#hasGeometry> ${geometryId}`;

  return rdfTriples;
}