import { overwriteFile } from '@inrupt/solid-client';

export async function saveCropfieldToSolidPod(session, id, rdfString) {
  if (!session.info.isLoggedIn) {
    throw new Error('The session is not authenticated.');
  }
  // Construct the save location
  const webId = new URL(session.info.webId);
  const baseLocation = new URL('/', webId.toString()).toString();
  let folder = 'Percelen/';
  if (webId.origin === 'https://test.vaa.az.kpn-dsh.com')
    folder = 'DggkPod/Percelen/';
  const folderLocation = new URL(folder, baseLocation).toString();

  const filename = id;
  const fileLocation = new URL(filename, folderLocation).toString();
  // Save the RDF string to the file
  try {
    await overwriteFile(
      fileLocation,
      new Blob([rdfString], { type: 'text/plain' }), // changed to turtle future
      { fetch: session.fetch },
    );
  } catch (error) {
    throw new Error(`Error saving data: ${error}`);
  }
}
