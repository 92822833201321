import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ConfigProvider, Spin } from 'antd';
import nlNL from 'antd/lib/locale/nl_NL';
import { ErrorBoundary } from 'components';
import { AuthComponent } from 'components/authentication/authComponent';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Provider } from 'jotai';
import { Suspense } from 'react';
import { Router } from 'react-router-dom';
import history from 'utilities/history';
import { InitWrapper } from './InitWrapper';
import { Body } from './body';
import './styles.scss';

library.add(far, fas);
dayjs.extend(isoWeek);
dayjs.locale('nl');
declare global {
  interface Window {
    less: any;
  }
}

export const App = () => {
  return (
    <Provider>
      <Suspense fallback={<Spin />}>
        <AuthComponent>
          <InitWrapper>
            <Router history={history}>
              <ConfigProvider locale={nlNL}>
                <ErrorBoundary>
                  <Body />
                </ErrorBoundary>
              </ConfigProvider>
            </Router>
          </InitWrapper>
        </AuthComponent>
      </Suspense>
    </Provider>
  );
};
