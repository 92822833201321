import type { Session } from '@inrupt/solid-client-authn-browser';
import {
  LoginButton,
  LogoutButton,
  SessionProvider,
} from '@inrupt/solid-ui-react';
import { Select, Space, notification } from 'antd';
import { CustomButton } from 'components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
export type SolidProvider = {
  label: string;
  url: string;
  storageType: string;
  folderName: string;
};

export const PROVIDERS: SolidProvider[] = [
  {
    label: 'Inrupt',
    url: 'https://login.inrupt.com/',
    storageType: 'http://www.w3.org/ns/pim/space#storage',
    folderName: 'Percelen/',
  },
  {
    label: 'VAA',
    url: 'https://test.vaa.az.kpn-dsh.com/',
    storageType: 'http://www.w3.org/ns/solid/terms#oidcIssuer',
    folderName: 'DggkPod/Percelen/',
  },
  {
    //https://dggk-a.vaacloud.nl/localhostwebid.json
    //&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fplots%2Fsolid
    label: 'WUR',
    url: 'https://id.farmpod.eu/auth?client_id=https%3A%2F%2Fdggk-a.vaacloud.nl%2Flocalhostwebid.json',
    storageType: '',
    folderName: 'Percelen/',
  },
  {
    label: 'Solid community',
    url: 'https://solidcommunity.net/',
    storageType: 'http://www.w3.org/ns/pim/space#storage',
    folderName: 'Percelen/',
  },
];

interface SolidLoginProps {
  session: Session;
  oid?: SolidProvider;
  setOid: React.Dispatch<React.SetStateAction<SolidProvider | undefined>>;
}
const SolidLogin: FC<SolidLoginProps> = ({ session, oid, setOid }) => {
  const nav = useHistory();
  const restoreCallback = (url: string) => {
    console.log(`Use this function to navigate back to ${url}`);
  };
  const refresh = () => {
    nav.push('/plots/solid');
  };
  return (
    <>
      {session?.info?.isLoggedIn === true ? (
        <Space>
          <SessionProvider
            sessionId="VAA_DGGK_logout"
            onError={(e) => notification.error({ message: e })}
            restorePreviousSession
            onSessionRestore={restoreCallback}
          >
            <LogoutButton onLogout={() => refresh()}>
              <CustomButton color="primary" type="primary" shape="round">
                Log uit
              </CustomButton>
            </LogoutButton>
          </SessionProvider>
          ingelogd als: {session.info.webId}
        </Space>
      ) : (
        <>
          <Space>
            <Select
              style={{ width: 200 }}
              onSelect={(e) =>
                setOid(PROVIDERS.find((f) => f.url === e?.toString()))
              }
            >
              {PROVIDERS.map((option, i) => (
                <Select.Option key={`${i} ${option}`} value={option.url}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
            <SessionProvider
              sessionId="VAA_DGGK"
              onError={(e) => notification.error({ message: e })}
              restorePreviousSession
              onSessionRestore={restoreCallback}
            >
              <LoginButton
                oidcIssuer={oid?.url || ''}
                onError={(e) => notification.error({ message: e })}
                redirectUrl={`${window.location.protocol}//${window.location.host}/plots/solid`}
              >
                <CustomButton color="primary" type="primary" shape="round">
                  Log In
                </CustomButton>
              </LoginButton>
            </SessionProvider>
          </Space>
        </>
      )}
    </>
  );
};

export default SolidLogin;
