import { useFile } from '@inrupt/solid-ui-react';
import { useEffect, useState } from 'react';
import useRDFToGeoJSON from 'utilities/hooks/rdfHooks/useRDFToGeoJson';
import { RvoCard } from './RvoCard';
import { Feature } from '@turf/helpers';
import { GeoJsonProperties, Geometry } from 'geojson';

type SolidCardType = {
  setSolidPlots: React.Dispatch<
    React.SetStateAction<Feature<Geometry, GeoJsonProperties>[]>
  >;
  checkSelected: (id: string) => boolean;
  toggleSelected: (id: string) => void;
  url: string;
};
export const SolidCard = ({
  setSolidPlots,
  checkSelected,
  toggleSelected,
  url,
}: SolidCardType) => {
  const [file, setFile] = useState<string>();
  const geojson = useRDFToGeoJSON(
    file || '',
    url.split('/')[url.split('/').length - 1],
  );
  const { data } = useFile(url);
  useEffect(() => {
    data?.text().then((value) => {
      setFile(value);
    });
  }, [data]);
  useEffect(() => {
    if (geojson)
      setSolidPlots((plots) => {
        return [...plots.filter((f) => f.id !== geojson?.id), geojson];
      });
  }, [geojson, setSolidPlots]);

  return (
    geojson && (
      <RvoCard
        toggleSelected={toggleSelected}
        checkSelected={checkSelected}
        plot={geojson}
      />
    )
  );
};
