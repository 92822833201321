import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDataset, useSession } from '@inrupt/solid-ui-react';
import {
  getCropTypes,
  getRvoCropfields,
  getSubfields,
  postSubfields,
} from 'adapters/cropfield-adapter';
import { redirectToRVO } from 'adapters/pvs-rvo-adapter';
import { message } from 'antd';
import { atoms } from 'atoms';
import { CustomButton, CustomCard } from 'components';
import { Container } from 'components/grid';
import { Feature } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { Provider, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CropType } from 'types';
import { defaultSorter } from 'utilities/default-sorter';
import { MonsterCard } from './MonsterCard';
import { PlotCard } from './PlotCard';
import { PlotHeader } from './PlotHeader';
import { RvoCard } from './RvoCard';
import { SolidCard } from './SolidCard';
import SolidLogin, { PROVIDERS, SolidProvider } from './solid-pods/login';
import styles from './styles.module.scss';

const CONTAINER_CONTAINS = 'http://www.w3.org/ns/ldp#contains';

export const Plots = () => {
  const history = useHistory();
  let { page = 'perceel' } =
    useParams<{ page: 'rvo' | 'perceel' | 'monsters' | 'solid' }>();
  const [sortBy] = useState<string>('name');
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [plots, setItems] = useAtom(atoms.plots);
  const [extPlots, setExtPlots] = useState<Feature[]>([]);
  const [solidPlots, setSolidPlots] = useState<Feature[]>([]);
  const [cropTypes, setCropTypes] = useState<CropType[]>([]);
  const [user] = useAtom(atoms.user);
  const [oid, setOid] = useState<SolidProvider | undefined>();
  const { session } = useSession();
  const { dataset: userData } = useDataset(session.info.webId);
  const storage = useMemo(() => {
    if (!userData) return undefined;
    const graphDefault: any = userData?.graphs.default;
    const predicates = graphDefault[`${session.info.webId}`].predicates;
    const storageName =
      predicates[oid?.storageType!]?.namedNodes[0] + oid?.folderName;
    return storageName as string;
  }, [oid?.folderName, oid?.storageType, session.info.webId, userData]);
  const { dataset: storageData } = useDataset(storage);

  const datasets = useMemo(() => {
    const regex = new RegExp(/.{8}-.{4}-.{4}-.{4}-.{12}/gm);
    let datasets: string[] = [];
    if (!storageData) return undefined;
    const graphDefault: any = storageData?.graphs.default;
    const predicates = graphDefault[`${storage}`].predicates;
    datasets = predicates[CONTAINER_CONTAINS].namedNodes.filter((f: string) => {
      const splits = f.split('/');
      return splits[splits?.length - 1].match(regex);
    });
    return datasets;
  }, [storage, storageData]);

  const setScreen = (screen: 'rvo' | 'perceel' | 'monsters' | 'solid') => {
    const searchParams = new URLSearchParams(window.location.search);
    const path = '/plots' + (screen === 'perceel' ? '' : '/' + screen);
    history.push({
      pathname: path,
      search: '?' + searchParams.toString(),
    });
  };

  useEffect(() => {
    if (!session.info.isLoggedIn) setOid(undefined);
    if (session.info.webId) {
      const match = session.info.webId!.substring(0, 14);
      const provider = PROVIDERS.find((f) => f.url.substring(0, 14) === match);
      setOid(provider || PROVIDERS[0]);
    }
    getRvoCropfields({ year: selectedYear }).then((res) => setExtPlots(res));
    getCropTypes({ year: selectedYear }).then((res) => setCropTypes(res));
    getSubfields({ year: selectedYear }).then((res) => {
      setItems(res);
    });
  }, [selectedYear, session.info.isLoggedIn, session.info.webId, setItems]);

  const checkSelected = (id: string) => selectedIds.includes(id);
  const addSelected = (id: string) =>
    setSelectedIds((current) => [...current, id]);
  const toggleSelected = (id: string) => {
    if (checkSelected(id)) removeSelected(id);
    else addSelected(id);
  };
  const removeSelected = (id: string) =>
    setSelectedIds((current) => current.filter((selected) => selected !== id));
  const selectAll = () =>
    setSelectedIds(extPlots.map((plot) => plot?.properties?.id));
  const getPlot = (id: string) =>
    extPlots.find((plot) => plot?.properties?.id === id);

  const savePlots = async (newPlots: Feature[]) => {
    const currentPlots = [...plots, ...newPlots];
    try {
      const response = await postSubfields(
        { year: selectedYear },
        currentPlots,
      );
      setItems(response);
      setScreen('perceel');
      message.success(
        'Geselecteerde percelen overgenomen naar gebruiksperceel',
      );
    } catch {
      message.error(
        'Het overnemen naar gebruikspercelen is mislukt. Probeer het opnieuw.',
      );
    }
  };

  const useRVOPlots = async () => {
    const list = await makeListForGebruikspercelenExport();
    await savePlots(list);
  };
  const useSolidPlots = async () => {
    const plots = solidPlots.filter((f) =>
      selectedIds.includes((f.id as string) || ''),
    );
    const list = plots.map((plot) => {
      let newPlot = plot;
      newPlot.id = undefined;
      (plot.properties as any).id = undefined;
      return newPlot;
    });
    await savePlots(list);
  };

  const makeListForGebruikspercelenExport = async () => {
    return selectedIds.map((id) => {
      const plot = getPlot(id) as Feature;
      plot.id = undefined;
      (plot.properties as any).id = undefined;
      (plot.properties as any).cropTypeId = (plot.properties as any).cropTypeId;
      (plot.properties as any).name = (plot.properties as any).name;
      (plot.properties as any).area = (plot.properties as any).area;
      (plot.properties as any).isClaimed = true;
      return plot;
    });
  };

  const makeListForSolidPodExport = async () => {
    return selectedIds.map((id) => {
      const plot = getPlot(id) as Feature;
      return plot;
    });
  };

  return (
    <>
      <PlotHeader
        screen={page || 'perceel'}
        setScreen={setScreen}
        selectAll={selectAll}
        useRVOPlots={useRVOPlots}
        useSolidPlots={useSolidPlots}
        makeListForSolidPodExport={makeListForSolidPodExport}
        session={session}
        plots={plots}
      />
      {user && page === 'rvo' && (
        <Container>
          <CustomButton
            color="primary"
            type="primary"
            shape="round"
            icon={<FontAwesomeIcon icon={faDownload} />}
            onClick={() => redirectToRVO(selectedYear, user.kvk)}
          >
            Haal mijn percelen op bij RVO
          </CustomButton>
        </Container>
      )}
      {page === 'solid' && (
        <Container>
          <SolidLogin oid={oid} setOid={setOid} session={session} />
        </Container>
      )}
      {page === 'monsters' ? (
        <Container>
          <CustomCard>
            <MonsterCard />
          </CustomCard>
        </Container>
      ) : (
        <div className={styles.vaaContent}>
          {page === 'rvo' && (
            <>
              {extPlots.map((plot) => (
                <RvoCard
                  checkSelected={checkSelected}
                  toggleSelected={toggleSelected}
                  plot={plot}
                />
              ))}
            </>
          )}

          {page === 'perceel' && (
            <>
              {plots
                .sort(
                  defaultSorter((plot) =>
                    sortBy === 'crop'
                      ? plot.properties?.crop
                      : plot.properties?.name,
                  ),
                )
                .map((plot) => (
                  <PlotCard item={plot} />
                ))}
            </>
          )}
          {page === 'solid' && (
            <>
              {datasets?.map((url) => (
                <SolidCard
                  setSolidPlots={setSolidPlots}
                  checkSelected={checkSelected}
                  toggleSelected={toggleSelected}
                  url={url}
                />
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
};
