import { Feature } from 'geojson';
import { useEffect, useState } from 'react';

const useRDFToGeoJSON = (rdfData: string, id: string) => {
  const [geoJSON, setGeoJSON] = useState<Feature | null>(null);

  useEffect(() => {
    if (!rdfData) return;

    const lines = rdfData.split('\n');

    const labelLine = lines.find((line) =>
      line.includes('http://www.w3.org/2000/01/rdf-schema#label'),
    );
    const name = labelLine ? labelLine.split('"')[1] : 'Geen label gevonden';

    const coordinates =
      lines
        .filter((line) =>
          line.includes('http://www.opengis.net/ont/geosparql#asWKT'),
        )
        .map((line) => {
          return line
            .match(/POLYGON\(\((.*)\)\)/)?.[1]
            ?.split(',')
            .map((coord) => coord.trim().split(' ').map(parseFloat));
        })[0] ?? [];

    const feature: Feature = {
      type: 'Feature',
      id: id,
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
      properties: {
        name: name,
        id: id,
        cropTypeId: '2a82d838-6138-401b-9f41-61a9c911dff2',
        cropCategory: 'Overig',
        cropType: 'Overige',
      },
    };

    setGeoJSON(feature);
  }, [rdfData, id]);

  return geoJSON;
};

export default useRDFToGeoJSON;
